<template>
  <div role="main" class="main">
    <section class="common-page speaker-room-page position-relative" id=image v-if="isFullscreen==false">
      <div class="container-fluid">
        <div class="row" style="height: calc(42vh - 61px)">
          <div class="col-lg-4" id="container4"></div>
          <div class="col-lg-4">
            <div class="user-wrapper h-100">
              <div class="container-fluid wrap">
                <div class="row h-100">
                  <div class="col-md-12">
                    <div class="position-relative h-100" >
                      <div
                        class="live-video h-100"
                        style="
                          padding: 0 0;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <div class="h-100" style="width: 100%;position: relative" v-if="!isSafari">
                          <div
                            class="frame-wrapper"
                            style="width: 100%"
                            v-if="viewerRoomUrl != ''"
                          >
                            <!-- <iframe
                              id="framedata"
                              style=""
                              src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                              allow="autoplay; encrypted-media"
                              class="frame_wrapper"
                            ></iframe> -->
                            <div class="h-100"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                          </div>
                        </div>
                        <div style="width: 100%; position: relative" v-if="isSafari">
                          <div
                            class="frame-safari-wrapper"
                            style="width: 100%"
                          >
                            <!-- <iframe
                              id="framedata"
                              style=""
                              src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                              allow="autoplay; encrypted-media"
                              class="frame_wrapper"
                            ></iframe> -->
                            <div ><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                          </div>
                        </div>
                      </div>
                      <div class="position-absolute" style="right:-6px;bottom:4px;z-index:999">
                        <v-btn
                        rounded
                        class="play-btn"
                        text
                        @click="changeScreenView()"
                        >
                        <!-- <div class="close-btn" style="background: #000;
    border-radius: 50px;
    padding: 5px;
    height: 35px;
    width: 35px;"> -->
                        <img
                          style="height:33px;background-color: black;" 
                          :src="maximize"
                          />
                        <!-- </div> -->
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4" id="container5"></div>
        </div>
        <div class="row m-0" style="height: calc(42vh - 61px)">
          <div class="col-lg-4" id="container1"></div>
          <div class="col-lg-4" id="container2"></div>
          <div class="col-lg-4" id="container3"></div>
        </div>
        <div class="row m-0" style="height: calc(20vh - 61px)">
        <div class="col-lg-12">
          <div class="user-card-wrapper">
            <p
              class="text-left pr-2"
              style="font-size: 24px; color: white !important"
            >
              <b style="background-color: black;padding: 2px;">LIVE MESSAGES:</b>
            </p>

            <div
              class="my-auto d-flex align-items-center"
              v-if="
                latest_profile_url != '' &&
                latest_profile_name != ''
              "
            >
              <div class="d-flex align-items-start" style="background-color: black;padding: 5px;">
                <div class="profile-pic border-0 mb-2">
                  <img
                    :src="latest_profile_url"
                    class="mr-3 position-relative"
                    style="
                      width: 80px;
                      height: 80px;
                      top: unset;
                      left: unset;
                      border-radius: 8px;
                      border: 3px solid #c1540c;
                    "
                  />
                </div>

                <div>
                  <div
                    class="text-left mb-0"
                    style="
                      font-size: 22px;
                      font-weight: 600;
                      color: white;
                    "
                  >
                    {{ latest_profile_name }}
                  </div>
                  <div
                    class="text-left mb-0"
                    style="
                      overflow-y: auto;
                      max-height: 100px;
                      color: white;
                    "
                  >
                    {{ latest_message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
     
    </section>
    <section class="common-page speaker-room-page position-relative" id=image v-if="isFullscreen==true">
       <div class="container-fluid">
        <div class="row">
          <div style="width:100%" class="col-lg-12 position-relative">
              <div style="position: relative" v-if="!isSafari">
                <div
                  style="width: 100%"
                >
                  <!-- <iframe
                    id="framedata"
                    style="width: calc(100vw - 61px);height: calc(70vh - 61px)"
                    src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                    allow="autoplay; encrypted-media"
                    class="frame_wrapper"
                  ></iframe> -->
                  <div style="padding:67vh 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                </div>
              </div>
              <div style="width: 100%; position: relative" v-if="isSafari">
                <div
                  class="frame-safari-wrapper"
                  style="width: 100%"
                >
                  <!-- <iframe
                    id="framedata"
                    style=""
                    :src="viewerRoomUrl"
                    allow="autoplay; encrypted-media"
                    class="frame_wrapper"
                  ></iframe> -->
                  <div style="padding:67vh 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                </div>
              </div>
           
            <div class="position-absolute" style="right:2px;bottom:14px">
              <v-btn
              rounded
              class="play-btn"
              text
              @click="changeScreenView()"
              >
              <!-- <div class="close-btn" style="background: rgb(0, 0, 0);
                          border-radius: 50px;
                          padding: 2px;
                          height: 35px;
                          width: 35px; line-height: 30px;"> -->
                <img
                style="height:33px;background-color: black;"
                :src="minimize"
                />
              <!-- </div> -->
              </v-btn>
            </div>
          </div>
        </div>
        <div class="row m-0" style="height: calc(20vh - 61px)">
          <div class="col-lg-12">
            <div class="user-card-wrapper">
              <p
                class="text-left pr-2"
                style="font-size: 24px; color: white !important"
              >
              <b style="background-color: black;padding: 2px;">LIVE MESSAGES:</b>
              </p>

              <div
                class="my-auto d-flex align-items-center"
                v-if="
                  latest_profile_url != '' &&
                  latest_profile_name != ''
                "
              >
                <div class="d-flex align-items-start" style="background-color: black;padding: 5px;">
                  <div class="profile-pic border-0 mb-2">
                    <img
                      :src="latest_profile_url"
                      class="mr-3 position-relative"
                      style="
                        width: 80px;
                        height: 80px;
                        top: unset;
                        left: unset;
                        border-radius: 8px;
                        border: 3px solid #c1540c;
                      "
                    />
                  </div>

                  <div>
                    <div
                      class="text-left mb-0"
                      style="
                        font-size: 22px;
                        font-weight: 600;
                        color: white;
                      "
                    >
                      {{ latest_profile_name }}
                    </div>
                    <div
                      class="text-left mb-0"
                      style="
                        overflow-y: auto;
                        max-height: 100px;
                        color: white;
                      "
                    >
                      {{ latest_message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import {
  admin_key,
  apiBaseUrl,
  company_prefix,
  liveBaseUrl,
} from "../../constants/config";
import Vue from 'vue'
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);
import Room from "janus-room";
import { janusUrl, iceServer } from "../../constants/config";
import { Draggable } from "draggable-vue-directive";
import adapter from "webrtc-adapter";
import $ from "jquery";
import maximize from "../../../src/assets/img/fullScreen.png"
import minimize from "../../../src/assets/img/normalScreen.png"
window["adapter"] = adapter;

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      handleId: "handle-id",
      draggableValue: {
        handle: undefined,
      },
      video_source: "",
      runningVideo: false,
      showbroad: true,
      runningSourceVideo: false,
      event_id: 0,
      speakers: [],
      // visitors: [],
      event: null,
      visitor_id: 0,
      connection: null,
      room: null,
      current_seq_name: "",
      current_user: null,
      current_speaker: null,
      notes: "",
      condolences: [],
      show_css_layout: false,
      params: { autoplay: 0 },
      youtube_url: "",
      show_modal: true,
      latest_message: "",
      latest_visitor_id: "",
      show_count_modal: false,
      count_number: 25,
      latest_profile_url: "",
      latest_profile_name: "",
      nextspeaker: false,
      viewerRoomUrl: "",
      extra: false,
      extraforty: false,
      extraeighty: false,
      isSafari: false,
      isFullscreen:false,
      maximize:maximize,
      minimize:minimize,
    };
  },
  mounted() {
    if(this.$browserDetect.isSafari){
      this.isSafari = true
    }
    this.draggableValue.handle = this.$refs[this.handleId];
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).id
      : 0;
    this.getDetail();
    this.getEventDetail();
    this.connectSocket();
    this.getCondolences();

  },
  methods: {
    changeScreenView(){
      this.isFullscreen=this.isFullscreen==true?false:true;
      if(this.isFullscreen==false){
        this.getDetail();
      }
    },
    chunkify(a, n, balanced) {
      if (n < 2) return [a];
      var len = a.length;
        var out = [];
        console.log("ppppppp",out)
        var i = 0;
       var size;
      if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
          out.push(a.slice(i, (i += size)));
        }
      } else if (balanced) {
        while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, (i += size)));
        }
      } else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0) size--;
        while (i < size * n) {
          out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
      }
      console.log("ppoooooo",out)
      for (var j = 0; j < out.length; j++) {
        this.show_circles("container" + [j + 1], out[j]);
      }
    },
     show_circles(contain, sizes) {
      var max = 60;
      var min = 60;
      // Convert the sizes to percentage range where lowest number is 0 and max number is 100%
      var percentages = sizes.map(function (x) {
        var value = "";
        if (min - max == 0) {
          value = 1;
        } else {
          value = min - max;
        }
        return ((60 - min) * 100) / value;
      });
      // Sort descending
      percentages.sort(function (a, b) {
        return b - a;
      });
      // Get the container element and work out various sizes
      var container = document.getElementById(contain);
      container.innerHTML = '';
      var width = container.clientWidth;
      var height = container.clientHeight;
      var area = width * height;
      // Work out area of largest circle
      var maxCircleArea = area / sizes.length;
      // use pi to get the max circle radius and then set min circle as a percentage of max
      var pi = Math.PI;
      var maxRadius = Math.sqrt(maxCircleArea / pi);
      var minRadius = maxRadius * 0.5;
      // Set a range and create an array of circle radius values
      var range = maxRadius - minRadius;
      var radii = percentages.map(function (x) {
        return (x / 100) * range + minRadius;
      });
      function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }

      function drawCircles(e, i) {
        var circle = document.createElement("div");
        circle.className = "bubble";
        circle.style.backgroundImage = "url('" + sizes[i].profile_url + "')";
        circle.style.backgroundSize= 'cover';
        circle.style.width = e.radius * 1.3 + "px";
        circle.style.height = e.radius * 1.3 + "px";
        circle.style.top = e.top + "px";
        circle.style.left = e.left + "px";
        // circle.innerText = i;
        return container.appendChild(circle);
      }
      // Generate some random plot points to place the circle
      function plotCircles(radiiArray) {
        var randomTop = getRandomArbitrary(0, height);
        var randomLeft = getRandomArbitrary(0, width);
        var top =
          randomTop + radiiArray[0] * 2 < height
            ? randomTop
            : randomTop - radiiArray[0] * 2 >= 0
            ? randomTop - radiiArray[0] * 2
            : randomTop - radiiArray[0];
        var left =
          randomLeft + radiiArray[0] * 2 < width
            ? randomLeft
            : randomLeft - radiiArray[0] * 2 >= 0
            ? randomLeft - radiiArray[0] * 2
            : randomLeft - radiiArray[0];
        var x = left + radiiArray[0];
        var y = top + radiiArray[0];

        return {
          x: x,
          y: y,
          radius: radiiArray[0],
          top: top,
          left: left,
        };
      }

      function loop(iter, radiiArray) {
        var counter = 0;
        // Nested for loops in this function will compare every element of array with each other
        function looper(iter, radiiArray) {
          for (var i = 0; i < coords.length; i++) {
            for (var k = i + 1; k < coords.length; k++) {
              // using the equation we see if the current set of plot points will overlap others
              var x1 = coords[i].x;
              var x2 = coords[k].x;
              var y1 = coords[i].y;
              var y2 = coords[k].y;
              var r1 = coords[i].radius;
              var r2 = coords[k].radius;
              // (x1 - x2)^2 + (y1 - y2)^2 < (radius1 + radius2)^2 -
              // equation to detect if two circles on a plane intersect
              var overlapped =
                (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) <
                (r1 + r2) * (r1 + r2);

              if (overlapped) {
                // Generate a new set of plot points to try
                coords.splice(iter, 1, plotCircles(radiiArray));
                counter += 1;
                // This restarts the loop to try again
                return true;
              }
            }
          }
        }
        // We run the looper function in a while loop so can restart
        // and carry on when an overlap is detected
        while (looper(iter, radiiArray)) {
          console.log("here");
        }
        return;
      }
      // Set up a recursive function to loop through radii array
      // after the first iteration it runs the loop function which
      // starts to compare every set of plots in the coords array
      // for overlaps. When it hits an overlap it restarts the loop
      // until it passes. When all have passed the function returns
      var coords = [];
      var count = -1;
      var redo = false;
      function recurse(radiiArray) {
        if (!radiiArray.length) {
          return;
        }
        var iter = (count += 1);
        // Add the first circle's plot points to the coords array
        // every circle's points will be added to this array once
        // we know they will not intersect
        coords.push(plotCircles(radiiArray));
        if (iter > 0) {
          // Start loop
          loop(iter, radiiArray);
        }

        return recurse(radiiArray.slice(1));
      }

      recurse(radii);

      // Render the circles from set of coordinates that we know should contain no overlaps
      coords.forEach(drawCircles);
    },

    closeModal() {
      this.show_modal = false;
    },
    getEventDetail() {
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
          event_id: this.event_id,
        })
        .then((result) => {
          if (result.data.status == true) {
            let object=document.getElementById('image')
           if (result.data.data[0].background_url!=null && result.data.data[0].background_url!='') {
              object.style.backgroundImage="url('" + `${apiBaseUrl}` + "/" + result.data.data[0].background_url + "')";
            }
            this.viewerRoomUrl =
              result.data.data[0].viewerRoomUrl +
              "&minimal&chat=off&displayName=test&leaveButton=off&bottomToolbar=off";
          }
        });
    },
    live() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          wed_name: this.event.wed_name,
        })
        .then((result) => {
          this.countDownTimer();
          setTimeout(() => {
            this.youtube_url = result.data.channel_id + "&autoplay=1";
            this.show_count_modal = false;
          }, 25000);
        });
    },
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_live") {
          this.nextspeaker = true;
          // setTimeout(() => {
          ref.receiveLiveUser(data.order, data.speaker);
          // }, 10000);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "end_live") {
          console.log("oooooo",data)

          //route after finishes lives
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/visitor/parting/"+data.event_id+"?x=" + randomnumber);
        } else if (data.type == "change_speaker_bio") {
          ref.current_speaker = data.speaker;
        } else if (data.type == "get_message") {
          ref.getCondolences();
        }else if(data.type == "update_visitors"){
          ref.getDetail()
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    showCss() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 25000);
    },
    showCssforswitchspeaker() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      this.count_number = 15;
      this.countDownTimer();
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 15000);
    },
    showCssforswitchspeakerwithvideo() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      this.count_number = 15;
      this.countDownTimer();
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 15000);
    },
    countDownTimer() {
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.show_count_modal = false;
      }
    },
    getCondolences() {
      let ref = this;
      let i = 0;
      getApiManager()
        .post(`${apiBaseUrl}/api/admin/condolence/all`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          this.condolences = [];
          if (res.status === true) {
            let data = res.data;
            if (data.length > 0) {
              let u_id = data[0].content_type_id
                ? data[0].content_type_id
                : data[0].visitor_id;
              let u_type = data[0].content_type_id ? "speaker" : "visitor";
              let condolence = {
                profile_url: data[0].content_type_id
                  ? apiBaseUrl + "/" + data[0].profile_url
                  : apiBaseUrl + "/" + data[0].visitor_profile_url,
                profile_name: data[0].content_type_id
                  ? data[0].speaker_name
                  : data[0].full_name,
                messages: [],
              };
              condolence.messages.push(data[0].message);
              this.latest_message = data[i].message;
              this.latest_visitor_id = data[i].content_type_id
                ? data[i].content_type_id
                : data[i].visitor_id;
              this.latest_profile_url = data[i].content_type_id
                ? apiBaseUrl + "/" + data[i].profile_url
                : apiBaseUrl + "/" + data[i].visitor_profile_url;
              this.latest_profile_name = data[i].content_type_id
                ? data[i].speaker_name
                : data[i].full_name;

              setInterval(() => {
                i++;
                if (i == data.length) {
                  i = 0;
                }
                this.latest_message = data[i].message;
                this.latest_visitor_id = data[i].content_type_id
                  ? data[i].content_type_id
                  : data[i].visitor_id;
                this.latest_profile_url = data[i].content_type_id
                  ? apiBaseUrl + "/" + data[i].profile_url
                  : apiBaseUrl + "/" + data[i].visitor_profile_url;
                this.latest_profile_name = data[i].content_type_id
                  ? data[i].speaker_name
                  : data[i].full_name;
              }, 30000);
            }
          }
        })
        .catch((error) => {});
    },
    getDetail() {
      var ref = this;
      ref.visitors=[]
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/all`, { event_id: this.event_id })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            ref.speakers = detail.speakers;
            for (var i = 0; i < ref.speakers.length; i++) {
              ref.speakers[i].profile_url =
                apiBaseUrl + "/" + ref.speakers[i].profile_url;
            }
            console.log("8888888",detail.visitors)
            ref.visitors.push(...detail.visitors);
            console.log("000000000",ref.visitors)

            for (i = 0; i < ref.visitors.length; i++) {
              ref.visitors[i].profile_url =
                apiBaseUrl + "/" + ref.visitors[i].profile_url;
            }
            if(this.isFullscreen==false){
              ref.chunkify(ref.visitors, 5, true);
            }
            ref.event = detail.event;
            ref.setCurrentOrder(ref.event.cur_order);
            ref.setCurrentUser(ref.event.cur_speaker);
            // ref.startLive();
            ref.showCss();
            this.live();
          }
        })
        .catch((error) => {});
    },
    setCurrentUser(speaker) {
      this.event.cur_speaker = speaker;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == speaker) {
          this.current_user = this.speakers[i];
        }
      }
      if (this.current_user.speaker_type == 0) {
        this.setVideoUrl(this.current_user.video_url);
      } else {
        this.runningSourceVideo = false;
        this.video_source = "";
        this.$forceUpdate();
      }
    },
    setCurrentOrder(order) {
      this.event.cur_order = order;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].order == order) {
          this.current_seq_name = this.speakers[i].name;
        }
      }
    },
    setVideoUrl(url) {
      this.runningVideo = false;
      this.showbroad = false;
      this.runningSourceVideo = true;

      if (url.includes("watch") == true) {
        if (
          url.split("=")[1] != null &&
          url.split("=")[1] != undefined &&
          url.split("=")[1] != ""
        ) {
          setTimeout(() => {
            this.video_source =
              "https://www.youtube.com/embed/" +
              url.split("=")[1] +
              "?autoplay=1";
          }, 25000);
        } else {
          setTimeout(() => {
            this.video_source = url;
          }, 25000);
        }
      } else {
        setTimeout(() => {
          this.video_source = url;
        }, 25000);
      }
      this.params = { autoplay: 1 };
      this.$forceUpdate();
    },
    receiveLiveUser(order, speaker) {
      this.nextspeaker = false;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == speaker) {
          this.current_user = this.speakers[i];
          if (this.speakers[i].speaker_type == 0) {
            this.showCssforswitchspeakerwithvideo();
          } else {
            this.showCssforswitchspeaker();
          }
        }
      }

      this.event.cur_order = order;
      this.event.cur_speaker = speaker;
      this.setCurrentOrder(order);
      this.setCurrentUser(speaker);
    },

    onMessage(data) {
      console.log(data);
    },
    onError(err) {
      console.log(err);
    },
    goBack() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
    },
  },
  components: {},
  computed: {
    counter() {
      return this.$store.getters.token;
    },
  },
  watch: {},
};
</script>
<style>
.speaker-room-page {
  background-image: url("../../assets/img/video-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  padding-top: 9rem;
  padding-bottom: 0;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
}
.frame-wrapper  {
  height: 100%;
    overflow: hidden;
    margin: 0 auto;
}
.frame-wrapper-rectangle  {
    height: 250px !important;
    width: 300px !important;
    overflow: hidden;
    margin: 0 auto;
    border: 2px solid red;
}
.frame-wrapper iframe {
    height: 300px;
    position: relative !important;
    width: 460px;
    top: -40px;
    left: -110px;
}
.frame-safari-wrapper  {
    
}
.frame-safari-wrapper iframe {
    height: 300px;
    margin: 0 auto;
    border-radius: 50%;
}
.box {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 150px;
  position: absolute;
}

.guest-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  max-height: 58vh;
}
.user-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.bubble {
  border-radius: 50%;
  position: absolute;
  border: 3px solid #fff;
}
.play-btn{
    /* background-color: #fcd351 !important; */
    margin-top: 10px;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 10px 10px 10px 10px!important;

}
</style>
<!-- <style>
.speaker-room-page {
  background-image: url("../../assets/img/video-bg.png");
  background-repeat: no-repeat;
  background-position: left;
  padding-top: 9rem;
  padding-bottom: 0;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
}
.frame-wrapper  {
    
}
.frame-wrapper iframe {
    height: 300px;
  
    margin: 0 auto;
    border-radius: 50%;
}
.box {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 150px;
  position: absolute;
}

.guest-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  max-height: 58vh;
}
.user-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.bubble {
  border-radius: 50%;
  position: absolute;
  border: 3px solid #fff;
}
</style> -->
